import { getExpressionParser } from '../expressionParser';
import { createJsonBuilder } from '../jsonBuilder';
import { getVariableParser } from '../variableParser';
import { getSessionClient } from '../sessionClient';
import { parseArray, stringifyArray } from './formula-utils/arrayFormulas';
export const createReduceArrayParser = () => {
    const expressionParser = getExpressionParser();
    const jsonBuilder = createJsonBuilder();
    const variableParser = getVariableParser();
    const sessionClient = getSessionClient();
    const parse = async (data) => {
        const { arrayReference, resultReference, currentItemReference, indexReference, reducedItem } = data.data;
        // parse array
        const { value: rawArrayValue } = await expressionParser.parse(arrayReference, 'strip');
        const parsedArray = parseArray(rawArrayValue);
        // parse variable IDs
        const resultReferenceId = variableParser.extractReferenceId(resultReference);
        const currentItemReferenceId = variableParser.extractReferenceId(currentItemReference);
        const indexReferenceId = variableParser.extractReferenceId(indexReference);
        let reducedValue = reducedItem.type === 'Number' ? '0' : '';
        if (resultReferenceId) {
            await sessionClient.write({
                referenceId: resultReferenceId,
                value: reducedValue
            });
        }
        for (const index in parsedArray) {
            let currentItem = parsedArray[index];
            if (currentItem) {
                if (Array.isArray(currentItem)) {
                    currentItem = stringifyArray(currentItem);
                }
                else if (typeof currentItem === 'object') {
                    currentItem = JSON.stringify(currentItem);
                }
                else {
                    currentItem = `${currentItem}`;
                }
            }
            // update loop values
            await Promise.all([
                indexReferenceId ? sessionClient.write({ referenceId: indexReferenceId, value: index }) : null,
                currentItemReferenceId ? sessionClient.write({ referenceId: currentItemReferenceId, value: currentItem }) : null
            ]);
            // compute result
            if (reducedItem.type === 'Object') {
                const parsedObject = await jsonBuilder.buildJSON({}, reducedItem.value);
                reducedValue = JSON.stringify(parsedObject);
            }
            else {
                reducedValue = (await expressionParser.parse(reducedItem.value, 'strip')).value;
            }
            if (resultReferenceId) {
                await sessionClient.write({
                    referenceId: resultReferenceId,
                    value: reducedValue
                });
            }
        }
        return {
            value: reducedValue,
            isPlainText: true
        };
    };
    return {
        parse
    };
};
