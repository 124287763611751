// @ts-check
import { pinia } from '@/plugins/pinia';
import { useApplicationStore } from '@/store/applicationStore';
import { useVariableStore } from '@/store/variableStore';
import { useWorkflowStore } from '@/store/workflowStore';
import { createConfigManager } from '@/lib/nuclicore-core';

const variableStore = useVariableStore(pinia);
const workflowStore = useWorkflowStore(pinia);
const applicationStore = useApplicationStore(pinia);

export const configManager = createConfigManager();

configManager.defineConfig({
    variableParser: {
        processValue: async (variable) => {
            let value = '';
            if (variable !== undefined && !variableStore.isVariableIgnored(variable.reference)) {
                value = variable.value;
                // @TO-DO's: escape html was done for APPBLDR-663 where primary issue was wiping mailgun ID's inside <>, investigate if this logic is really needed for frontend
                // if (shouldEscapeHtml) {
                //     value = escapeHtml(value);
                // }
            }
            return value;
        }
    },
    sessionClient: {
        read: async (...variableIds) => {
            const variables = variableIds.map(variableId => variableId in workflowStore.workflowsVariables ? workflowStore.workflowsVariables[variableId] : variableStore.variables[variableId]).filter(variable => variable && !variable.isValueIgnored).map(variable => variable.value);
            return variables;
        },
        write: async (payload) => {
            if(payload.referenceId){
                applicationStore.shouldUpdateForm = false;
                variableStore.updateVariableById(
                    {
                        type: 'variable',
                        value: payload.referenceId
                    },
                    {
                        value: typeof payload.value === 'string' ? payload.value : null
                    }
                );
            }
        }
    }
});
