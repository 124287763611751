import { createSessionClient } from './sessionClient';
import { createVariableParser } from './variableParser';
import { createFormulaParser } from './formulaParser';
import { createExpressionParser } from './expressionParser';
import { createWorkflowEngine } from './workflowEngine';
let config = null;
export const createConfigManager = () => {
    const defineConfig = (configParams) => {
        config = { ...configParams };
    };
    const init = () => {
        if (config === null) {
            throw '@/lib/nuclicore-core/esm: No configuration is defined in config manager for initialization.';
        }
        else {
            createSessionClient(config.sessionClient);
            createVariableParser(config.variableParser);
            createExpressionParser();
            createFormulaParser();
            createWorkflowEngine();
        }
    };
    return {
        defineConfig,
        init
    };
};
