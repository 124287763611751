import { isPrimitve } from '../../helpers/utils';
import { getExpressionParser } from '../expressionParser';
export const createAccessPropertyParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const { objectReference, accessPath } = data.data;
        const { value } = await expressionParser.parse(objectReference, 'unwrap');
        const parsedObject = parseObject(value);
        const { value: path } = await expressionParser.parse(accessPath, 'strip');
        let parsedValue = '';
        if (parsedObject) {
            const accessedValue = path.split('.').reduce((result, key) => {
                if (!isPrimitve(result)) {
                    if (Array.isArray(result)) {
                        result = result[parseInt(key)];
                    }
                    else {
                        result = result[key];
                    }
                }
                return result;
            }, parsedObject);
            if (isPrimitve(accessedValue)) {
                parsedValue = accessedValue;
            }
            else {
                parsedValue = JSON.stringify(accessedValue);
                if (Array.isArray(accessedValue)) {
                    parsedValue = `{${parsedValue.substring(1, parsedValue.length - 1)}}`;
                }
            }
        }
        return {
            value: `${parsedValue}`,
            isPlainText: true
        };
    };
    const parseObject = (data) => {
        try {
            // handle parsing object
            const objectSymbols = ['{', '['];
            if (data && !objectSymbols.includes(data.charAt(0)) && !objectSymbols.includes(data.charAt(data.length - 1))) {
                // value is primitive, convert to array
                data = `["${data}"]`;
            }
            const parsedObject = JSON.parse(data);
            return parsedObject;
        }
        catch (err) {
            // otherwise, JSON is not valid, handle parsing arrays
            let parsedObject = null;
            if (data.charAt(0) === '{' && data.charAt(data.length - 1) === '}') {
                data = `[${data.substring(1, data.length - 1)}]`;
                parsedObject = JSON.parse(data);
            }
            return parsedObject;
        }
    };
    return {
        parse
    };
};
