import * as cheerio from 'cheerio';
import { getSessionClient } from './sessionClient';
import { isJSON } from '../helpers/utils';
export let variableParser = null;
export const createVariableParser = (variableParserConfig) => {
    const sessionClient = getSessionClient();
    const parse = async (rawValue) => {
        let parsedValue = rawValue;
        if (rawValue) {
            const htmlDoc = cheerio.load(rawValue);
            const body = htmlDoc.root().find('body');
            const elements = body.find('.mention');
            for (const element of elements) {
                const variableId = cheerio.default(element).data('mention-id');
                let [variableValue] = await sessionClient.read(variableId);
                variableValue = await variableParserConfig.processValue({
                    reference: variableId,
                    value: variableValue
                });
                if (variableValue === undefined) {
                    variableValue = '';
                }
                else {
                    if (!isJSON(variableValue) && variableValue.startsWith('{"')) {
                        const innerValue = variableValue.replace('{"', '').replace('"}', '').trim();
                        if (innerValue.split(',').length === 1) {
                            variableValue = innerValue;
                        }
                    }
                }
                parsedValue = parsedValue.replace(cheerio.default(element).wrap('<span>').parent().html() || '', variableValue);
                parsedValue = parsedValue.trim();
            }
        }
        return parsedValue;
    };
    const extractReferenceId = (rawValue) => {
        if (rawValue) {
            const htmlDoc = cheerio.load(rawValue);
            const body = htmlDoc.root().find('body');
            const elements = body.find('.mention');
            const referenceId = elements ? cheerio.default(elements[0]).data('mention-id') : null;
            return referenceId;
        }
        return null;
    };
    variableParser = {
        parse,
        extractReferenceId,
        ...variableParserConfig
    };
    return getVariableParser();
};
export const getVariableParser = () => {
    if (variableParser === null) {
        throw '@/lib/nuclicore-core/esm: variable parser is not configured';
    }
    return variableParser;
};
