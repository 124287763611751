import { getSessionClient } from './sessionClient';
import { getExpressionParser } from './expressionParser';
const shouldSkipValidation = (field) => {
    const excludedTypes = ['next-btn', 'back-btn', 'e-signature', 'hidden', 'data-table', 'text', 'document'];
    return excludedTypes.includes(field.type);
};
export const createFormValidator = () => {
    const sessionClient = getSessionClient();
    const expressionParser = getExpressionParser();
    const validateField = async (field) => {
        var _a;
        const emailRegex = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
        const errors = [];
        if (!shouldSkipValidation(field)) {
            if (field.show && ((_a = field.properties) === null || _a === void 0 ? void 0 : _a.validation)) {
                let [fieldValue] = await sessionClient.read(field.id);
                if (field.properties.validation.isRequired) {
                    if (field.type === 'html-editor') {
                        fieldValue = expressionParser.unwrap(fieldValue);
                    }
                    if (['', null, undefined].includes(fieldValue) && !['address', 'payment-gateway', 'tile'].includes(field.type)) {
                        errors.push({
                            id: field.id,
                            message: [field.properties.validation.isRequiredMessage || '']
                        });
                    }
                    if (field.type === 'email-input') {
                        if (fieldValue && !emailRegex.test(fieldValue.toLowerCase())) {
                            errors.push({
                                id: field.id,
                                message: [field.properties.validation.emailMessage || field.properties.validation.message || '']
                            });
                        }
                    }
                    if (field.type === 'number-input') {
                        const numberInputValue = Number(fieldValue);
                        if (isNaN(numberInputValue)) {
                            errors.push({
                                id: field.id,
                                message: [field.properties.validation.message || '']
                            });
                        }
                        else {
                            let isRangeError = false;
                            if (field.properties.basic.min) {
                                const minLimit = await expressionParser.parse(field.properties.basic.min, 'strip');
                                isRangeError = numberInputValue < parseFloat(minLimit.value);
                            }
                            if (field.properties.basic.max) {
                                const maxLimit = await expressionParser.parse(field.properties.basic.max, 'strip');
                                isRangeError = isRangeError || numberInputValue > parseFloat(maxLimit.value);
                            }
                            if (isRangeError) {
                                errors.push({
                                    id: field.id,
                                    message: [field.properties.validation.rangeErrorMessage || '']
                                });
                            }
                        }
                    }
                    if (field.type === 'address') {
                        const [cityValue, zipCodeValue, streetValue, houseNumber, countryValue, stateValue, districtValue] = await sessionClient.read(`city_${field.id}`, `zipCode_${field.id}`, `street_${field.id}`, `number_${field.id}`, `country_${field.id}`, `state_${field.id}`, `district_${field.id}`);
                        if (!(cityValue && zipCodeValue && streetValue && houseNumber
                            && (!field.properties.basic.hasCountry || countryValue)
                            && (!field.properties.basic.hasState || stateValue)
                            && (!field.properties.basic.hasDistrict || districtValue))) {
                            errors.push({
                                id: field.id,
                                message: [field.properties.validation.isRequiredMessage || '']
                            });
                        }
                    }
                    if (field.type === 'tile') {
                        const [selectedTileId] = await sessionClient.read(`id_${field.id}`);
                        if (!selectedTileId) {
                            errors.push({
                                id: field.id,
                                message: [field.properties.validation.isRequiredMessage || '']
                            });
                        }
                    }
                    if (field.type === 'payment-gateway') {
                        const [paymentStatusValue] = await sessionClient.read(field.id);
                        if (paymentStatusValue !== 'SUCCESS') {
                            errors.push({
                                id: field.id,
                                message: [field.properties.validation.isRequiredMessage || '']
                            });
                        }
                    }
                    if ('hasRegex' in field.properties.validation && field.properties.validation.hasRegex && field.properties.validation.regex) {
                        const fieldRegex = new RegExp(field.properties.validation.regex);
                        if (!fieldRegex.test(fieldValue)) {
                            errors.push({
                                id: field.id,
                                message: [field.properties.validation.hasRegexMessage || '']
                            });
                        }
                    }
                }
            }
        }
        return errors;
    };
    const validateForm = async (form) => {
        var _a, _b, _c;
        const result = {
            isValid: true,
            errors: []
        };
        if (form === undefined) {
            return result;
        }
        const fieldValidationQueue = (_b = (_a = form === null || form === void 0 ? void 0 : form.sections) === null || _a === void 0 ? void 0 : _a.filter(section => section.show)) === null || _b === void 0 ? void 0 : _b.flatMap(section => section.fields.flatMap(column => column.filter(field => 'show' in field && field.show).flatMap(field => {
            var _a;
            let validationQueue = [];
            if (field.type === 'grid') {
                const gridValidationQueue = (_a = field.properties.basic.cells) === null || _a === void 0 ? void 0 : _a.data.flatMap(row => row.filter(cell => { var _a, _b, _c; return (_c = (_b = (_a = cell.properties) === null || _a === void 0 ? void 0 : _a.basic) === null || _b === void 0 ? void 0 : _b.element) === null || _c === void 0 ? void 0 : _c.type; }).map(cell => validateField(cell.properties.basic.element)));
                validationQueue = validationQueue.concat(gridValidationQueue);
            }
            else {
                validationQueue = [validateField(field)];
            }
            return validationQueue;
        })));
        result.errors = (fieldValidationQueue === null || fieldValidationQueue === void 0 ? void 0 : fieldValidationQueue.length) ? (_c = (await Promise.all(fieldValidationQueue))) === null || _c === void 0 ? void 0 : _c.flatMap(errors => errors) : [];
        if (result.errors.length) {
            result.isValid = false;
        }
        return result;
    };
    return {
        validateField,
        validateForm
    };
};
