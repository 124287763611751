import axios from 'axios';
import { getExpressionParser } from '../expressionParser';
export const createBase64EncoderParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const parsedExpression = await expressionParser.parse(data.data.content, 'strip');
        if (parsedExpression.value) {
            const response = await axios.get(parsedExpression.value, { responseType: 'arraybuffer' });
            parsedExpression.value = Buffer.from(response.data).toString('base64');
        }
        else {
            parsedExpression.value = '';
        }
        parsedExpression.isPlainText = true;
        return parsedExpression;
    };
    return {
        parse
    };
};
