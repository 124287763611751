import { getExpressionParser } from '../expressionParser';
export const createNumberFormatterParser = () => {
    const expressionParser = getExpressionParser();
    const replaceSeparators = (value, decimalMark, groupDelimiter) => {
        return value.replace(/\./g, 'DECIMAL').replace(/,/g, 'GROUP')
            .replace(/DECIMAL/g, decimalMark).replace(/GROUP/g, groupDelimiter);
    };
    const parse = async (data) => {
        var _a, _b;
        const { value: parsedNumber } = await expressionParser.parse(data.data.number, 'strip');
        const { value: parsedDecimalPlaces } = await expressionParser.parse(data.data.decimalPlaces, 'strip');
        const { value: customFormat } = await expressionParser.parse(((_a = data.data.currency) === null || _a === void 0 ? void 0 : _a.custom) || '', 'strip');
        const { value: numeralDecimalMark } = await expressionParser.parse(data.data.numeralDecimalMark || '', 'strip');
        const { value: delimiter } = await expressionParser.parse(data.data.delimiter || '', 'strip');
        const formatMapper = {
            'Comma': 'en-US',
            'United States': 'en-US',
            'Germany': 'de-DE',
            'Period': 'de-DE',
        };
        const format = formatMapper[data.data.format] || 'en-US';
        let formatOptions = {
            style: data.data.currency.show ? 'currency' : 'decimal'
        };
        if (((_b = data.data.currency) === null || _b === void 0 ? void 0 : _b.type) === 'Custom') {
            formatOptions.style = 'decimal';
        }
        else {
            formatOptions.currency = data.data.currency.type;
        }
        if (parsedDecimalPlaces) {
            formatOptions = { ...formatOptions, ...{
                    minimumFractionDigits: parseInt(parsedDecimalPlaces),
                    maximumFractionDigits: parseInt(parsedDecimalPlaces),
                } };
        }
        const formatter = new Intl.NumberFormat(format, formatOptions);
        let formattedValue = formatter.format(parseFloat(parsedNumber));
        if (data.data.format === 'Custom') {
            formattedValue = replaceSeparators(formattedValue, numeralDecimalMark, delimiter);
        }
        else if (data.data.format === 'None') {
            formattedValue = replaceSeparators(formattedValue, '.', '');
        }
        if (data.data.currency.type === 'Custom') {
            formattedValue = !data.data.currency.currencySymbolAsSuffix ? customFormat + ' ' + formattedValue : formattedValue + ' ' + customFormat;
        }
        return {
            value: formattedValue,
            isPlainText: true
        };
    };
    return {
        parse
    };
};
