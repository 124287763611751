import { getExpressionParser } from '../expressionParser';
import { Parser as ExcelFormulaParser } from 'hot-formula-parser';
import { decode } from 'html-entities';
export const createArithmeticCalculationParser = () => {
    const excelFormulaParser = new ExcelFormulaParser();
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const parsedExpression = await expressionParser.parse(data.data.formula, 'strip');
        const validatedFormula = replaceInvalidValues(parsedExpression.value);
        const formula = decode(validatedFormula);
        const parsedFormula = excelFormulaParser.parse(formula);
        const parsedValue = {
            value: '',
            isPlainText: parsedExpression.isPlainText
        };
        if (!parsedFormula.error) {
            parsedValue.value = parsedFormula.result;
        }
        return parsedValue;
    };
    const replaceInvalidValues = (expression) => {
        const baseOperatorRegex = /([+\-*/])/;
        const leadingOperatorRegex = new RegExp(`^\\s*${baseOperatorRegex.source}\\s*`);
        const trailingOperatorRegex = new RegExp(`\\s*${baseOperatorRegex.source}\\s*$`);
        const invalidOperatorSequenceRegex = new RegExp(`${baseOperatorRegex.source}\\s+${baseOperatorRegex.source}`, 'g');
        // Replace leading '+' or '-' with '0 + ' or '0 - ' respectively
        expression = expression.replace(leadingOperatorRegex, '0 $1 ');
        // Replace trailing '+' or '-' with '0'
        expression = expression.replace(trailingOperatorRegex, ' $1 0');
        // Replace any invalid empty values between operators with '0'
        expression = expression.replace(invalidOperatorSequenceRegex, '$1 0 $2');
        return expression.trim();
    };
    return {
        parse
    };
};
