import { getExpressionParser } from '../expressionParser';
export const createInsertFormattedTextParser = () => {
    const expressionParser = getExpressionParser();
    const parse = async (data) => {
        const parsedExpression = await expressionParser.parse(data.data.text, 'skip');
        parsedExpression.isPlainText = false;
        return parsedExpression;
    };
    return {
        parse
    };
};
